body {
    background: rgb(78,195,207);
    background: linear-gradient(180deg, rgba(78,195,207,0.3) 0%, rgba(14,95,159,0.3) 100%) fixed;
}

 #plant {
 /*   background-color: steelblue;
    background: linear-gradient(to bottom right, #007bca60, #268fdf60);
 */
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 26% 27% 3% 15% 29%;
    grid-template-rows: 20% 24% 10% 46%;
    grid-template-areas:
    "hall61 logo logo hall83 hall83"
    "hall61 hall39 hall39 hall39 hall38"
    "hall41 hall39 hall39 hall39 hall38"
    "hall41 hall21 kpi kpi kpi"
}
@media only screen and (max-width: 1270px) {
    #plant {
        grid-template-columns: 26% 27% 3% 15% 29%;
        grid-template-rows: 20% 24% 10% 46%;
        grid-template-areas:
        "hall61 logo logo hall83 hall83"
        "hall61 hall39 hall39 hall39 hall38"
        "hall41 hall39 hall39 hall39 hall38"
        "hall41 hall21 kpi kpi kpi"
    }
}
@media only screen and (max-width: 600px) {
    #plant {
        grid-template-columns: 100%;
        grid-template-rows: 100px;
        grid-template-areas:
        "logo"
        "hall61"
        "hall41"
        "hall39"
        "hall21"
        "hall83"
        "hall38"
        "kpi"
    }
    .production-hall {
        margin-top: 1em;
    }
    .production-line {
        min-height: 32px;
    }
    #logo {
        border: none;
        margin-top: 0;
    }
}

#plant #hall-61 {
    grid-area: hall61;
}

#plant #hall-41 {
    grid-area: hall41;
}

#plant #hall-39 {
    grid-area: hall39;
    flex-direction: column;
}

#plant #hall-21 {
    grid-area: hall21;
}

#plant #hall-83 {
    grid-area: hall83;
    flex-direction: column;
}

#plant #hall-38 {
    grid-area: hall38;
}

/* */
#plant #hall-61 .rejection-rate {
    flex-direction: column;
}
#plant #hall-41 .rejection-rate {
    flex-direction: column;
}
#plant #hall-21 .rejection-rate {
    flex-direction: column;
}
#plant #hall-38 .rejection-rate {
    flex-direction: column;
}
#plant #hall-39 .rejection-rate {
    flex-direction: row;
}
#plant #hall-83 .rejection-rate {
    flex-direction: row;
}

#plant #logo {
    grid-area: logo;
    /* background-color: darkgrey; */
    

    /* background: linear-gradient(to bottom right, #007bca, #268fdf); */
}

#plant #kpi {
    grid-area: kpi;
    /* background-color: darkgray; */
}

.production-hall {
    position: relative;
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1.5em;
}

.production-hall .label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.4em;
    border-bottom: 0.1px solid black;
}

.production-line {
    position: relative;
    flex-basis: 40%;
    text-align: center;
    border: 1px solid black;
    margin: 1%;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
    padding-top: 10px;
}

.production-line:hover {
    box-shadow: 0px 3px 2px -2px rgba(0,0,0,0.8), 0px 2px 2px 0px rgba(0,0,0,0.54), 0px 2px 4px 0px rgba(0,0,0,0.52);
}

@media only screen and (max-width: 600px) {
.production-line .status {
    display: none;
}
}
@media only screen and (min-width: 601px) {
.production-line .status {
    display: flex;
}
}

.production-line .status {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
    top: 2px;
}

.production-line .status .last-rejection-info {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
}

.production-line .status .rejection-rate {
    width: 100%;
    height: 100%;
    margin: auto;
    font-size: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    /*align-content: flex-start;*/
}

.production-line .status .rejection-rate .rejection-rate-pct {
    font-size: 1em;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.production-line .status .rejection-rate .rejection-rate-pct div {
    height: 1em;
    font-size: 1em;
}

.production-line .status .rejection-rate .rejection-rate-cost {
    font-size: 1em;
    margin-bottom: 1.4em;
    flex-basis: 100%;
}

.production-line .status .rejection-rate .rejection-rate-cost div {
    font-size: 0.8em;
}

.production-line .status .rejection-rate .rejection-trend {
    font-size: 1em;
    flex-basis: 100%;
}

.production-line .status .rejection-rate .rejection-trend div {
    height: 1em;
    font-size: 1em;
}

.production-line .name {
    position: absolute;
    right: -3px;
    bottom: -2px;
    width: 60px;
    border-radius: 0 0 4px 0;
    font-size: medium;
}

@media only screen and (max-width: 1270px) {
    .production-line .name {
        width: 100%;
        font-size: small;
    }
}
@media only screen and (max-width: 600px) {
    .production-line {
        border-radius: 0px;
        padding-top: 0;
    }
    .production-line .name {
        position: relative;
        font-size: medium;
    }
}

.production-line.no-vision {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
}

.production-line.inactive {
    background: linear-gradient(to bottom right,#bbbbbb, #dddddd) !important;
}


.production-line.ok {
    background: linear-gradient(to bottom right, #48b461,#28a745);
}

.production-line.warning {
    background: linear-gradient(to bottom right,#ffc107, #ffca2c);
}

.production-line.danger {
    /*background: linear-gradient(to bottom right, #e15361,#dc3545);*/
    background: linear-gradient(to bottom right, #d15361,#dc3545);
}

.production-line.ready {
    /*background: linear-gradient(to bottom right, #0275D8,#0170C8);*/
    background: linear-gradient(to bottom right, #804040,#a84a4a);
    }


.production-line-empty {
    flex-basis: 40%;
    /* margin: auto; */
    margin: 1%;
}

#hall-41 .production-line {
}

#hall-39 .production-line {
}

/*
@media only screen and (max-width: 6px) {
    #hall-39 .production-line, #hall-39 .production-line-empty  {
        writing-mode: tb-rl;
        flex-basis: 50%;
        margin: auto;
    }
    #hall-83 .production-line {
        writing-mode: tb-rl;
    }
}
*/

#hall-83 .production-line {
    flex-basis: 80%;
}

#visibility-icon
{
    position: 'absolute';
    font-size: '3rem';
    width: '1rem';
    height: '1rem'
}

.production-line-lists{
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    margin-left: 2em;
}

.production-line-lists .top-ejections {
    width: 80%;
    margin: 1em;
}

.production-line-lists .top-no-ejections {
    width: 80%;
    margin: 1em;
}

/*
#plant {
    width: 800px;
    height: 600px;
    background-color: grey;
    display: grid;
    grid-template-columns: 26% 27% 3% 15% 29%;
    grid-template-rows: 20% 24% 10% 46%;
}

#plant hall-61 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}

#plant hall-41 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 5;
}

#plant hall-39 {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
}

#plant hall-21 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
}

#plant hall-83 {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
}

#plant hall-38 {
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 4;
}

#plant logo {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}

#plant kpi {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 5;
}
*/
