.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* background-color: #EEEEEE; */
  background: rgb(200,221,221);
  /* background: linear-gradient(-45deg, rgba(1,221,221,1) 0%, rgba(255,255,255,1) 100%); */
  background: radial-gradient(circle, rgba(200,221,221,1) 0%, rgba(255,255,255,1) 90%);
  min-height: 100vh;
  /* display: flex; */
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
  color: black;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



* {
  font-family: 'Open Sans', sans-serif;
  font-size:12px;
}

body {
    /* background-color:#ececec;
    background: rgb(170,170,170);
    background: linear-gradient(0deg, rgba(170,170,170,1) 0%, rgba(238,238,238,1) 100%); */
}

.container {
  margin: 50px 0 0 100px;
}

.tile {
  width:160px;
  height:180px;
  border-radius:4px;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.15);
  margin-top:20px;
  margin-left:20px;
  float:left;
}

.tile.wide {
    top: 100px;
  width: 340px;
}

.tile .header {
  height:120px;
  background-color:#f4f4f4;
  border-radius: 4px 4px 0 0;
  color:white;
  font-weight:300;
}

.tile.wide .header .left {
    border-right: 1px dashed white;
}

.tile.wide .header .right {
    padding-left: 10px;
}

.tile.wide .header .left, .tile.wide .header .right {
  width:160px;
  float:left;
  padding-bottom: 16px;
}

.tile.wide .header .bottom  {
    margin-top: 4px;
    padding-right: 12px;
    position: relative;
}

.tile.wide .header .bottom .title {
  font-size: 16px;
}

.tile .header .count {
  font-size: 48px;
  text-align:center;
  padding:10px 0 0;
}

.tile .header .title {
  font-size: 12px;
  text-align:center;
}

.tile .body {
  height:60px;
  border-radius: 0 0 4px 4px;
  color:#333333;
  background-color:white;
}

.tile .body .title {
    text-align:center;
    font-size:20px;
    padding-top:2%;
}

.tile.wide .body .title {
  padding:0%;
  margin: auto;
}

.tile.job .header {
  background: linear-gradient(to bottom right, #609931, #87bc27);
}

.tile.job  .body {
  color: #609931;
}

.tile.resource .header {
  background: linear-gradient(to bottom right, #ef7f00, #f7b200);
}

.tile.resource  .body {
  color: #ef7f00;
}

.tile.quote .header {
  background: linear-gradient(to bottom right, #1f6abb, #4f9cf2);
}

.tile.quote  .body {
  color: #1f6abb;
}

.tile.invoice .header {
  background: linear-gradient(to bottom right, #0aa361, #1adc88);
}

.tile.invoice  .body {
  color: #0aa361;
}
